<template>
  <div class="static-page-layout">
    <template v-if="isLoading">
      <Load />
    </template>
    <template v-else>
      <div
        v-if="contentHtml"
        v-html="contentHtml"
        class="html-content"
      ></div>
      <div v-else>
        <div class="no-data-layout">
          <div>
            <img :src="logo" />
          </div>
          <div class="no-data text-3xl text-center">
            ขออภัย ไม่พบหน้าที่คุณต้องการในระบบ
          </div>
          <div class="text-center">
            <span class="text-center"
              >หน้านี้ไม่มีอยู่ในระบบหรือมีบางอย่างไม่ถูกต้อง
              กลับไปที่<span class="link" @click="goHome"
                >หน้าหลัก</span
              >หรือ<span @click="goContact" class="link"
                >ติดต่อเรา</span
              >เพื่อรับข้อมูลเพิ่มเติม
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Load from './Load'
import { mapState } from 'vuex'
import isBnn from '@/config/isBnn'
import { CONFIG_META } from '@/config/index'

export default {
  computed: {
    ...mapState({
      isLoading: state => state.staticPage.isLoading,
      staticPageData: state => state.staticPage.data,
    }),

    hasData() {
      return this.staticPageData?.length > 0
    },

    contentHtml() {
      return this.staticPageData?.[0]?.html
    },

    campaignType() {
      return process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'
    },

    logo() {
      if (this.campaignType === 'BNN') {
        return require(`@/assets/404-bnn.svg`)
      }
      return require(`@/assets/404-studio7.svg`)
    },
  },

  components: {
    Load,
  },

  async mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch(
        'staticPage/getStaticPage',
        this.$route.params.slug,
      )
    })
  },

  created() {
    // SEO add description and title
    // path /static/page/offer-prebook-iphone-16-series
    if (CONFIG_META.PATH.includes(this.$route.path)) {
      this.setMetaTag()
    }
  },

  methods: {
    goHome() {
      window.location.href = '/'
    },

    goContact() {
      this.campaignType === 'BNN'
        ? window.open(
            'https://www.facebook.com/Bananaitshop/',
            '_blank',
          )
        : window.open(
            'https://www.facebook.com/Studio7Thailand/',
            '_blank',
          )
    },

    setMetaTag() {
      const title = isBnn
        ? 'จอง iPhone 16 Series รุ่นใหม่ กับดีลสุดพิเศษที่ BaNANA Online'
        : 'สั่งจอง iPhone 16 Series รุ่นใหม่ได้แล้วที่ Studio7 Online'
      const content = isBnn
        ? 'สั่งจอง iPhone 16 Series ทุกรุ่นได้แล้วกับโปรโมชันสุดพิเศษที่ BaNANA Online ทั้ง เตรียมตัวเป็นเจ้าของ iPhone รุ่นใหม่ก่อนใครด้วยดีลสุดคุ้ม'
        : 'สั่งจอง iPhone 16 Series ได้แล้วที่ Studio7 Online พร้อมรับดีลสุดพิเศษก่อนใคร เตรียมตัวเป็นเจ้าของ iPhone รุ่นใหม่ล่าสุด กับโปรโมชันแบบสุดคุ้ม'

      document.title = title

      // Check if the meta description tag exists
      let metaDescription = document.querySelector(
        'meta[name="description"]',
      )
      if (!metaDescription) {
        // If it doesn't exist, create it
        metaDescription = document.createElement('meta')
        metaDescription.name = 'description'
        document.head.appendChild(metaDescription)
      }
      // Set the content attribute
      metaDescription.setAttribute('content', content)
    },
  },
}
</script>

<style lang="stylus" scoped>

.h-full
    height: 100%

.static-page-layout
    width: 100%
    height: 100%
    margin-top: 80px;

.no-data-layout
    margin-top: 150px
    padding: 0 2rem
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

.link
    color: $color-bnn
    cursor: pointer

.no-data
  position block
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  flex-direction: column
.desc-no-data
  width: 100%
.text-desc-no-data
    font-size: 1.875rem
@media screen and (max-width: 768px)
    .text-desc-no-data
        font-size: 1rem
</style>
